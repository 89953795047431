.head-container {
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: var(--main-header-background-color);
  border-bottom: 1px solid #e8e8e8;
  left: 0;
  right: 0;
  height: 65px;
  .head-box {
    position: relative;
    .inner {
      height: 64px;
      position: relative;
      max-width: 1140px;
      min-width: 960px;
      margin: 0 auto;
      .menu-box{
        width: calc(100% - 351px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        .menu-item{
          padding: 0;
          margin-right: 0;
          line-height: 64px;
          font-size: 16px;
          color: #000;
          cursor: pointer;
          margin-left: 53px;
        }
      }
    }
  }
}



