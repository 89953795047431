.logo-container {
  height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .logo-box {
    width: 28px;
    height: 28px;
    .logo-img {
      width: 100%;
      height: 100%;
    }
  }
  .logo-title {
    font-size: 20px;
    padding-left: 6px;
    color: rgba(0, 0, 0, 0.7);
  }
}



